import type { GatsbyBrowser } from "gatsby";

import React from "react";
import { createGlobalStyle } from "styled-components";

import normalize from "./normalize";
import style from "./style";

const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${style}
`;

const Page: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  // https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  );
};

export default Page;
